@font-face {
  font-family: 'NimbusSans';
  src: url('fonts/NimbusSans-Regular.ttf') format('truetype'),
       url('fonts/NimbusSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NimbusSans';
  src: url('fonts/NimbusSans-Bold.ttf') format('truetype'),
       url('fonts/NimbusSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --darkPurple: 294 100% 15.1%;
    --royalPurple: 307 94.5% 35.7%;
    --aspen: 23 100% 88.4%;
    --goldenBrass: 40 98.2% 42.9%;
    --graniteGrey: 0 0% 88.6%;
    --white: 0 0% 100%;
    --black: 0 0% 0%;
    --textBlack: 0 0% 14%;
    --grey: 0 0% 85%;
    --green: 88 48% 34%; /* #5A812D */
    --red: 343 100% 37%;
    --blue: 207 89% 43%; /* #0C77CF */

    --background: var(--white);
    --foreground: var(--textBlack);

    --heading: 349 17% 18%;

    --muted: 0 0% 40%;
    --muted-foreground: 215.4 16.3% 46.9%;
 
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;
 
    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;
 
    --border: var(--grey);
    --input: var(--grey);
 
    --primary: var(--royalPurple);
    --primary-foreground: var(--white);
 
    --secondary: var(--white);
    --secondary-foreground: var(--royalPurple);
 
    --accent: var(--darkPurple);  /* 210 40% 96.1%; */
    --accent-foreground: var(--white); /* 222.2 47.4% 11.2%; */
 
    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --neutral: var(--grey);
    --neutral-foreground: var(--black);

    --dark: var(--darkPurple);
    --dark-foreground: var(--white);
 
    --ring: 215 20.2% 65.1%;

    --status-neutral: 0 0% 35%; /* #595959 */

    --course-status-neutral: var(--status-neutral); /* #595959 */
    --course-status-success: var(--green); /* #8BC34A */
    --course-status-info: var(--blue); /* #D99216 */
    --course-status-warning: var(--red); /* #F44336 */
 
    --radius: 0.5rem;
  }
 
  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;

    --heading: 213 31% 91%;
 
    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;
 
    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;
 
    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;
 
    --border: 216 34% 17%;
    --input: 216 34% 17%;
 
    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;
 
    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;
 
    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;
 
    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;
 
    --ring: 216 34% 17%;
 
    --radius: 0.5rem;
  }
}
 
@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
  }
}

@layer components {
  .container {
    @apply sm:px-6 md:px-8;
  }
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-height: 100dvh;
}

body {
  font-family: "NimbusSans", Arial, sans-serif;
  background-color: #f9f9f9;
  width: 100%;
}
